import '../styles/index.scss';
import * as $ from 'jquery';
import Konva from 'konva';
import * as Assets from './assets';
import {SCALE_FACTOR} from "./config";
import {APP} from "./assets";
import {TimelineMax, TweenLite, Bounce} from 'gsap';
import {EGG} from "./assets";
import {EGG_RED} from "./assets";

const $app = $('#app');
const $sidebar = $('#sidebar-items');
const $jajco = $('#jajco-default');
const $jajcoRed = $('#jajco-red');
const tileLayerId = 'tile_layer';
const nodeLayerId = 'node_layer';
const indicatorLayerId = 'indicator_layer';
const teamLayerId = 'team_layer';

let foundCounter = 0;
let stage;
let drag = {
    startX: 0,
    startY: 0,
    x: 0,
    y: 0,
    dragging: false,
    target: null
};

$(window).on('load', () => {
    const appWidth = $app.width();
    const appHeight = $app.height();

    stage = new Konva.Stage({
        container: 'stage-parent',
        width: appWidth,
        height: appHeight
    });

    stage.on('mouseup', () => {
        mouseUp();
    });

    stage.on('mousedown', (event) => {
        mouseDown(event);
    });

    stage.on('mousemove', () => {
        mouseMove();
    });

    stage.on('wheel', function (event) {
        wheel(event);
    });

    $(document).on('mouseleave', () => {
        mouseUp();
    });

    $('.sidebar, .mini-map').on('mouseenter', () => {
        mouseUp();
    });

    $('.sidebar-debug').on('click', (e) => {
        debug($(e.target));
    });

    $('#btn-again').on('click', (e) => {
        reset();
    });

    $('.btn-close').on('click', (e) => {
        let $stage = $(e.target).closest('.stage');

        TweenLite.to($stage, 0.5, {
            opacity: 0, onComplete: () => {
                $stage.css('display', 'none');
            }
        });
    });

    $('.zoom-in').on('click', () => {
        zoom(1);
    });

    $('.zoom-out').on('click', () => {
        zoom(-1);
    });

    $(window).on('resize', () => {
        resize();
    });

    Assets.loadAll().then(() => {
        setupApp();
    });
});

function reset() {
    foundCounter = 0;
    let indicatorLayer = stage.findOne('#' + indicatorLayerId);
    indicatorLayer.destroyChildren();
    indicatorLayer.clear();

    stage.batchDraw();
    updateBoundaries(1, 1, false);
    center();
    stage.batchDraw();

    addSidebar();
}

function personClick(event) {
    if (event.target instanceof Konva.Rect) {
        if (event.target.attrs.found) {
            return;
        }

        event.target.attrs.found = true;

        let id = event.target.attrs.id;
        let $item = $('#item-' + id);
        let $clonedJajco = $($jajco.html());
        let $image = $item.find('img');

        $item.append($clonedJajco);

        let timeline = new TimelineMax();
        timeline.fromTo($clonedJajco, 0.7, {
            y: -20
        }, {
            y: 0,
            ease: Bounce.easeOut
        }, 'jajco');
        timeline.fromTo($clonedJajco, 0.4, {
            opacity: 0
        }, {
            opacity: 1
        }, 'jajco');

        $item.addClass('found');

        addIndicator(event.target);
        foundCounter++;

        let $stage = null;

        // switch(foundCounter) {
        //     case 1:
        //         $stage = $('.stage-2');
        //         break;
        //     case 2:
        //         $stage = $('.stage-3');
        //         break;
        //     case 3:
        //         $stage = $('.stage-4');
        //         break;
        //     case 4:
        //         $stage = $('.stage-5');
        //         break;
        //     case 5:
        //         $stage = $('.stage-6');
        //         break;
        // }

        let rewardCount = APP.rewardCount || APP.nodes.length;
        let step = Math.floor(rewardCount / 5);
        let stageNumber = null;

        switch (foundCounter) {
            case step:
                $stage = $('.stage-2');
                stageNumber = 2;
                break;
            case step * 2:
                $stage = $('.stage-3');
                stageNumber = 3;
                break;
            case step * 3:
                $stage = $('.stage-4');
                stageNumber = 4;
                break;
            case step * 4:
                $stage = $('.stage-5');
                stageNumber = 5;
                break;
            case APP.nodes.length:
                $stage = $('.stage-6');
                stageNumber = 6;
                break;
        }

        console.log('reward count:', foundCounter, '/', rewardCount);

        if ($stage) {
            showPopup($stage);

            gtag('event', 'stage', {
                'event_category': 'Stage',
                'event_label': 'Stage ' + stageNumber,
                'value': stageNumber,
                'non_interaction': true
            });
        }
    }
}

function teamClick(event) {
    if (event.target instanceof Konva.Rect) {
        if (event.target.attrs.found) {
            console.log('return');
            return;
        }

        event.target.attrs.found = true;
        addTeamIndicator(event.target);
    }
}

function showPopup($stage) {
    const timeline = new TimelineMax({delay: 0.7});

    $stage.css('display', 'block');
    timeline.to($stage, 0.1, {opacity: 1}, 'popup');
    timeline.to($stage.find('.popup'), 0.1, {opacity: 1});
    timeline.fromTo($stage.find('.popup-inner'), 0.5, {y: -30}, {y: 0, opacity: 1});
    timeline.fromTo($stage.find('.popup-hero'), 0.6, {x: 50}, {x: 0, opacity: 1}, '-=0.2');
}

function addTeamIndicator(target) {
    const layer = stage.findOne('#' + indicatorLayerId);
    const rect = target.getClientRect({relativeTo: stage});
    const image = new Image();

    image.onload = () => {
        let text = new Konva.Text({
            x: 20,
            y: 3,
            text: '.TOż .TO MOSQI.T!',
            fontSize: 32,
            fontFamily: 'DINProBold',
            fill: '#fff',
            opacity: 0
        });

        let bg = new Konva.Rect({
            x: 0,
            y: -10,
            width: text.width() + 40,
            height: text.height() + 20,
            opacity: 0
        });

        bg.cornerRadius(29);
        bg.fill('#DB1E47');
        bg.shadowEnabled(true);
        bg.shadowBlur(34);
        bg.shadowColor('#000');
        bg.shadowOpacity(0.5);
        bg.shadowOffsetY(2);

        let img = new Konva.Image({
            x: (bg.width() - image.width) / 2,
            y: bg.height() + 10,
            width: image.width,
            height: image.height,
            opacity: 0
        });

        img.image(image);

        let group = new Konva.Group({});
        group.add(bg);
        group.add(img);
        group.add(text);
        layer.add(group);

        let offsetX = (rect.width - bg.width()) / 2;

        console.log('offsetX', bg.width());

        group.position({
            x: rect.x + offsetX,
            y: rect.y - 140
        });

        layer.draw();

        let imgTween = new Konva.Tween({
            node: img,
            y: img.getClientRect({relativeTo: group}).y + 10,
            opacity: 1,
            duration: 0.7,
            easing: Konva.Easings.BounceEaseOut
        });

        let rectTween = new Konva.Tween({
            node: bg,
            y: 0,
            opacity: 1,
            duration: 0.3,
            easing: Konva.Easings.EaseInOut
        });

        let textTween = new Konva.Tween({
            node: text,
            y: 13,
            opacity: 1,
            duration: 0.3,
            easing: Konva.Easings.EaseInOut
        });


        imgTween.play();

        setTimeout(() => {
            rectTween.play();
            textTween.play();
        }, 200);

        setTimeout(() => {
            rectTween.reverse();
            textTween.reverse();
        }, 3000);
    };

    image.src = EGG_RED;
}


function addIndicator(target) {
    const layer = stage.findOne('#' + indicatorLayerId);
    const rect = target.getClientRect({relativeTo: stage});
    const image = new Image();

    image.onload = () => {
        let offsetX = (rect.width - image.width) / 2;

        let img = new Konva.Image({
            x: rect.x + offsetX,
            y: rect.y - image.height - 60,
            width: image.width,
            height: image.height,
        });

        img.image(image);
        layer.add(img);
        layer.draw();

        let tween = new Konva.Tween({
            node: img,
            y: rect.y - image.height - 10,
            duration: 0.7,
            easing: Konva.Easings.BounceEaseOut
        });

        tween.play();
    };

    image.src = EGG;
}

function wheel(event) {
    const oldScale = stage.scaleX();
    let scale = oldScale;

    console.log(event.evt);

    if (event.evt.deltaY < 0) {
        scale *= SCALE_FACTOR;
    } else {
        scale /= SCALE_FACTOR;
    }

    updateBoundaries(oldScale, scale, true);
}

function mouseUp() {
    drag.dragging = false;

    setTimeout(() => {
        drag.timeStamp = null;
    });
}

function mouseDown(event) {
    let rect = stage.getClientRect({});
    drag = {
        timeStamp: event.evt.timeStamp,
        startX: stage.getPointerPosition().x,
        startY: stage.getPointerPosition().y,
        x: rect.x,
        y: rect.y,
        dragging: true,
    };

    console.log('drag timestamp', drag.timeStamp);
}

function mouseMove() {
    if (!drag.dragging) {
        return;
    }

    let y = drag.y;
    let x = drag.x;

    let offsetX = (stage.getPointerPosition().x - drag.startX);
    let offsetY = (stage.getPointerPosition().y - drag.startY);

    y = y + offsetY;
    x = x + offsetX;

    if (x > 0) {
        x = 0;
    }

    if (y > 0) {
        y = 0;
    }

    let stageRect = stage.getClientRect({});
    let minX = (stageRect.width - stage.width()) * -1;
    if (x < minX) {
        x = minX;
    }

    let minY = (stageRect.height - stage.height()) * -1;
    if (y < minY) {
        y = minY;
    }

    stage.x(x);
    stage.y(y);

    stage.draw();

    updateMarker();
}

function resize() {
    const scale = stage.scaleX();
    stage.width($app.width());
    stage.height($app.height());

    stage.batchDraw();

    updateBoundaries(scale, scale, false);
}

function setupApp() {
    // add canvas element
    const nodeLayer = new Konva.Layer({id: nodeLayerId});
    const tileLayer = new Konva.Layer({id: tileLayerId});
    const indicatorLayer = new Konva.Layer({id: indicatorLayerId});
    const teamLayer = new Konva.Layer({id: teamLayerId});

    gtag('event', 'stage', {
        'event_category': 'Stage',
        'event_label': 'Stage 1',
        'value': 1,
        'non_interaction': true
    });

    stage.add(tileLayer);
    stage.add(indicatorLayer);
    stage.add(nodeLayer);
    stage.add(teamLayer);

    nodeLayer.on('click', function (event) {
        console.log('node click timestamp ', drag.timeStamp);
        if (drag.timeStamp) {
            let diff = event.evt.timeStamp - drag.timeStamp;

            console.log('diff', diff);

            if (diff > 300) {
                return;
            }
        }

        personClick(event);
    });

    teamLayer.on('click', (event) => {
        if (drag.timeStamp) {
            let diff = event.evt.timeStamp - drag.timeStamp;
            console.log('diff', diff);
            if (diff > 300) {
                return;
            }
        }

        teamClick(event);
    });

    addNodes(APP.nodes, nodeLayer, null);

    if (APP.addHiddenLayer) {
        addNodes(APP.team, teamLayer, null);
    }

    addEggs();
    addSidebar();

    loadOne().then(() => {
        resize();

        stage.batchDraw();
        center();
        stage.batchDraw();

        $('#wrapper').css('opacity', 1);
        setTimeout(() => {
            $('#loading').fadeOut(450, () => {
                setTimeout(() => {
                    $('#loading').remove();
                }, 100);
            });
        }, 100);
    });
}

function addEggs() {
    let jajco = $('#jajco-red').html();
    let html = '';

    for (let i = 0; i < APP.team.length; i++) {
        let item = APP.team[i];

        if (i % 2 === 0) {
            html += '<div class="egg">' + jajco + '</div>';
        }

        html += '<div class="egg"><img src="' + item.image + '"></div>';
    }

    $('.eggs').html(html);
}

function debug($target) {
    $target.toggleClass('enabled');

    let fill = $target.hasClass('enabled');
    addNodes(APP.nodes, stage.findOne('#' + nodeLayerId), fill ? 'red' : null);

    if (APP.addHiddenLayer) {
        addNodes(APP.team, stage.findOne('#' + teamLayerId), fill ? 'blue' : null);
    }
}

function updateMarker() {
    const $marker = $('#mini-map-mark');

    let stageSize = stage.getSize();
    let rect = stage.getClientRect({});

    $marker.css({
        left: ((Math.abs(rect.x) / rect.width) * 100) + '%',
        top: ((Math.abs(rect.y) / rect.height) * 100) + '%',
        width: ((stageSize.width / rect.width) * 100) + '%',
        height: ((stageSize.height / rect.height) * 100) + '%'
    });
}

function updateBoundaries(oldScale, scale, byMouse = false) {
    if (scale > 1.3) {
        return;
    }

    const box = stage.getClientRect({skipTransform: true});
    const stageSize = stage.getSize();
    let position = stage.position();

    if (isNaN(box.width) || isNaN(position.x)) {
        return;
    }

    const scaleX = stageSize.width / box.width;
    const scaleY = stageSize.height / box.height;
    const scaleValue = Math.max(scaleX, scaleY);

    if (scale < scaleValue) {
        scale = scaleValue;
    }

    stage.scale({x: scale, y: scale});

    const newWidth = box.width * scale;
    const newHeight = box.height * scale;

    if (byMouse) {
        let mousePointTo = {
            x: stage.getPointerPosition().x / oldScale - stage.x() / oldScale,
            y: stage.getPointerPosition().y / oldScale - stage.y() / oldScale
        };

        position = {
            x: -(mousePointTo.x - stage.getPointerPosition().x / scale) * scale,
            y: -(mousePointTo.y - stage.getPointerPosition().y / scale) * scale
        };

        console.log('after', position.x, stage.getPointerPosition().x);
    } else {
        console.log('position', position.x, stageSize.width, stageSize.width / 2);

        let centerX = (stageSize.width / 2);
        let centerY = (stageSize.height / 2);

        const mousePointTo = {
            x: centerX / oldScale - stage.x() / oldScale,
            y: centerY / oldScale - stage.y() / oldScale
        };

        position = {
            x: -(mousePointTo.x - centerX / scale) * scale,
            y: -(mousePointTo.y - centerY / scale) * scale
        };

        console.log('after', position.x, centerX);
    }

    if (position.x > 0) {
        position.x = 0;
    }

    if (position.y > 0) {
        position.y = 0;
    }

    if (newHeight + position.y < stageSize.height) {
        position.y = stageSize.height - newHeight;
    }

    if (newWidth + position.x < stageSize.width) {
        position.x = stageSize.width - newWidth;
    }

    stage.position(position);
    stage.batchDraw();

    updateMarker();
}

function center() {
    let rect = stage.getClientRect({});
    let stageSize = stage.getSize();

    stage.scale({x: 1, y: 1});
    stage.draw();

    stage.position({
        x: -((rect.width - stageSize.width) / 2),
        y: -((rect.height - stageSize.height) / 2)
    });

    updateMarker();
}

function addSidebar() {
    let panelHtml = '';
    let nodes = APP.nodes;

    $('#sidebar-header').html(APP.sidebarTOP);
    $('#sidebar-footer').html(APP.sidebarBOTTOM);

    for (let i = 0; i < nodes.length; i++) {
        let item = nodes[i];
        panelHtml += '<div class="sidebar-item" id="item-' + item.id + '"><img src="' + item.image + '"/></div>';
    }

    $sidebar.html(panelHtml);
}

function addNodes(array, layer, fill) {
    layer.destroyChildren();
    layer.clear();

    for (let i = 0; i < array.length; i++) {
        let item = array[i];
        item.fill = fill;

        layer.add(new Konva.Rect(item));
    }

    layer.draw();
}

function loadOne() {
    const tileLayer = stage.findOne('#' + tileLayerId);

    return new Promise((resolve, reject) => {
        let image = new Image();
        image.onload = function () {
            // create shape
            let img = new Konva.Image();
            img.image(image);

            tileLayer.add(img);

            resolve();
        };

        image.src = APP.image;
    });
}

function zoom(direction) {
    const originalScale = stage.scaleX();
    let scale = originalScale;

    if (direction > 0) {
        scale *= SCALE_FACTOR;
    } else {
        scale /= SCALE_FACTOR;
    }

    updateBoundaries(originalScale, scale, false);
}
