import * as $ from 'jquery';

export let APP = {};

export const EGG = '/public/images/jajco.svg';
export const EGG_RED = '/public/images/jajco-red.svg';

export const loadAll = () => {
    let promises = [];

    promises.push(new Promise((resolve, reject) => {
        let dataType = 'mosqito';
        let href = window.location.pathname;

        if (href.indexOf('abris') !== -1) {
            dataType = 'abris';
        } else if (href.indexOf('pelion') !== -1) {
            dataType = 'pelion';
        } else if (href.indexOf('ing') !== -1) {
            dataType = 'ing';
        } else if (href.indexOf('dziennikarze') !== -1) {
            dataType = 'dziennikarze';
        } else if(href === '/') {
            dataType = 'mosqito';
        } else {
            window.location.href = '/';
            return;
        }

        $.get('/public/data/' + dataType + '.json', (res) => {
            APP = res;

            let promises = APP.people.map(person => {
                person.id = randomID();
                return loadImage(person.image);
            });

            promises = promises.concat(APP.team.map(person => {
                person.id = randomID();
                return loadImage(person.image);
            }));

            Promise.all(promises).then(resolve);
        });
    }));

    promises.push(loadImage(EGG));
    promises.push(loadImage(EGG_RED));

    function loadImage(src) {
        return new Promise((resolve, reject) => {
            let image = new Image();
            image.onload = function () {
                resolve();
            };

            image.src = src;
        });
    }

    function randomID() {
        let chars = '0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghiklmnopqrstuvwxyz'.split('');
        let length = 8;

        if (!length) {
            length = Math.floor(Math.random() * chars.length);
        }

        let str = '';
        for (let i = 0; i < length; i++) {
            str += chars[Math.floor(Math.random() * chars.length)];
        }
        return str;
    }

    return Promise.all(promises).then(() => {
        let nodes = [].concat(APP.people);

        if(APP.showTeamInSidebar) {
            nodes = nodes.concat(APP.team);
        }

        APP.nodes = nodes;
    });
};
